! function () {

	// ajax api to the login controller
	function UserApi(config) {
		function decodeHtml(str) {
			var textArea = document.createElement('textarea');
			textArea.innerHTML = str;
			return textArea.value;
		}

		this.login = function(userType, cb) {
			console.log('login');
			var url = config.urlLogin;
			url = decodeHtml(url); // @todo this should in fact not be necessary
			
			var request = new XMLHttpRequest();
			request.overrideMimeType("application/json");
			request.open('POST', url, true);
			request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');

			request.onload = function() {
				var success = (request.status >= 200 && request.status < 400);
				if (cb) {
					cb(success);
				}
			};
			request.onerror = function() {
				if (cb) {
					cb(false);
				}
			};
			request.send(encodeURI('tx_opticafelogin_pi1[userType]=' + userType));
		}

		this.logout = function(cb) {
			console.log('logout');
			var url = config.urlLogout;
			var request = new XMLHttpRequest();
			request.open('GET', url, true);
			request.onload = function() {
				var success = (request.status >= 200 && request.status < 400);
				if (cb) {
					cb(success);
				}
			};
			request.onerror = function() {
				if (cb) {
					cb(false);
				}
			};
			request.send();
		}

		this.relogin = function(userType, cb) {
			var self = this;
			this.logout(function(success) {
				if (success) {
					self.login(userType, function(success) {
						if (cb) {
							cb(success);
						}
					});
				} else {
					if (cb) {
						cb(false);
					}
				}
			});
		}

		this.loginByForm = function(password, cb) {
			if (config.user) {
				return;
			}

			var url = config.urlLoginForm;
			url = decodeHtml(url);

			var request = new XMLHttpRequest();
			request.overrideMimeType("application/json");
			request.open('POST', url, true);
			request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');

			request.onload = function() {
				var data = request.response ? JSON.parse(request.response) : false;
				var success = (request.status >= 200 && request.status < 400) && (data && data.success);
				if (cb) {
					cb(success);
				}
			};
			request.onerror = function() {
				if (cb) {
					cb(false);
				}
			};
			request.send(encodeURI('tx_opticafelogin_pi2[password]=' + password));

		}
	}

	// handler to react on login and logout events (CMS)
	// used to redirect to a returnPage or reload the current one
	function LoginHandler(config, userApi) {

		this.afterCmsLogin = function(success) {
			if (success) {
				console.log('login detected');
				window.location.reload();
			} else {
				console.log('failed login detected');
				var e = new CustomEvent('cms-login-failed');
				document.dispatchEvent(e);
			}
		}
		this.afterCmsLogout = function(success) {
			if (success) {
				console.log('logout detected');
				window.location.reload();
			} else {
				console.log('failed logout detected');
			}
		}

		this.appUserAuthenticated = function(userType) {
			if (!config.user) {
				userApi.login(userType, this.afterCmsLogin.bind(this));
			} else {
				if (!config.userType || config.userType != userType) {
					userApi.relogin(userType, this.afterCmsLogin.bind(this));
				}
			}
		}

		this.appUserNotAuthenticated = function() {
			if (config.user && config.userType != 'guest') {
				userApi.logout(this.afterCmsLogout.bind(this));
			}
		}
	}

	// handler to react to users accessing protected content
	// use triggerProtectedContent() to activate automated handling
	// use canAccessProtectedContent() to handle the case manually
	function ContentProtection(config) {
		
		this.canAccessProtectedContent = function() {
			return !!config.user;
		}

		this.triggerProtectedContent = function() {
			if (this.canAccessProtectedContent()) {
				return true;
			}
			this.enforceLogin();
			return false;
		}

		this.enforceLogin = function() {
			if (window.lightbox) {
				window.lightbox.openIframe(this.getLoginPageUrl(), {
					afterClose: function() {
						var e = new CustomEvent('cms-login-canceled');
						document.dispatchEvent(e);
					}
				});
			}
		}

		this.getLoginPageUrl = function() {
			return config.urlRestrictionPopup;
		}
	}

	var config = window.loginHandlerConfig;
	var userApi = new UserApi(config);
	var loginHandler = new LoginHandler(config, userApi);
	var contentProtection = new ContentProtection(config);

	// make object publicly available
	// to enable custom login handling
	window.contentProtection = contentProtection;

	// listen to the portal api
	document.addEventListener('portalApi.user', function(e) {
		if (e.detail && e.detail.username) {
			if (e.detail.bkz) {
				if (e.detail.bkz == 'SH') {
					loginHandler.appUserAuthenticated('hilfsmittel');
				} else if (e.detail.bkz == 'MB') {
					loginHandler.appUserAuthenticated('heilmittel');
				} else {
					console.log('portalApi: unknown bkz: "' + e.detail.bkz + '"');
				}
			} else {
				console.log('portalApi: no bkz set, defaulting to heilmittel');
				loginHandler.appUserAuthenticated('heilmittel');
			}
		} else {
			loginHandler.appUserNotAuthenticated();
		}
	}, false);

	document.addEventListener('portalApi.logout-done', function(e) {
		loginHandler.appUserNotAuthenticated();
	});

	// listen to login and logout messages from other frames of this domain
	window.addEventListener('message', function(event) {
		if (window.location.origin.toLowerCase() == event.origin.toLowerCase() && event.data && event.data.event) {
			switch (event.data.event) {
				case 'cms-login':
					loginHandler.afterCmsLogin(true);
				break;
				case 'cms-logout':
					loginHandler.afterCmsLogout(true);
				break;
				case 'cms-login-failed':
					loginHandler.afterCmsLogin(false);
				break;
			}
		}
	}, false);

	// listen to events about accessing protected content
	document.addEventListener('protected-content', function(e) {
		console.log('protected content detected');
		// var key = e.detail && e.detail.location ? e.detail.location : false;
		var allowed = contentProtection.triggerProtectedContent();
		if (!allowed) {
			e.preventDefault();
		}
	});

	function loadHashSections() {
		var sections = {};
		var hash = window.location.hash.replace('#', '');
		if (hash) {
			var hashSections = hash.split('|');
			for (var i = 0; i < hashSections.length; i++) {
				var hashSection = hashSections[i];
				var hashSectionParts = hashSection.split('/');
				var hashSectionId = hashSectionParts.shift();
				var hashSectionValue = hashSectionParts.join('/');
				sections[hashSectionId] = hashSectionValue;
			}
		}
		return sections;
	}

	var hashSections = loadHashSections();
	if (hashSections.pwd) {
		userApi.loginByForm(hashSections.pwd, function(success) {
			loginHandler.afterCmsLogin(success);
		});
	}

} ();
